@import url("https://fonts.googleapis.com/css2?family=GT+Walsheim:wght@400&display=swap");
@font-face {
  font-family: "GT Walsheim";
  src: local("GT Walsheim"),
    url(./constants/fonts/GT-Walsheim-Medium.otf) format("opentype");
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: white;
  background-color: black;
  overflow-x: hidden;
}

code {
  font-family: "Inter", monospace;
}

.gt-walsheim {
  font-family: "GT Walsheim";
  font-size: 16px;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
